<!-- eslint-disable -->
<template>
  <v-container id="dashboard" fluid tag="section">
  <base-material-card icon="mdi-package-variant-closed" title="Liste des vendeurs" class="px-5 py-3">
    <vue-good-table
      max-height="700px"
      :columns="columns"
      :rows="rows"
      :pagination-options="{enabled: true, perPageDropdown:[10, 20,50,100,200],perPage:15}"
      style-class="vgt-table condensed bordered tableOne"
    >
    <template slot="loadingContent">
        <div class="spinner spinner-primary mx-auto my-auto"></div>
    </template>
    <template slot="column-filter" slot-scope="props">
      <b-form-datepicker v-if="props.column.filterOptions.customFilterDate" @input="handleCustomFilterDate"></b-form-datepicker>
    </template>
    <template slot="table-row" slot-scope="props">
        <span style="color:red;" v-if="props.column.field == 'incoming_packages'">
          {{props.row[props.column.field]}}
        </span>
        <span  v-else-if="props.column.field == 'name'">
          {{props.row[props.column.field]}}
          <v-icon v-if="props.row.dlv_type == 'homedlv'" style="float:right;" color='green'>mdi-home</v-icon>
          <v-icon v-if="props.row.dlv_type == 'stopdesk'" style="float:right;" color='green'>mdi-bank-outline</v-icon>
        </span>
        <span style="color:#4caf50;" v-else-if="props.column.field == 'outgoing_packages'">
          {{props.row[props.column.field]}}
        </span>
        <span v-else>
          {{props.row[props.column.field]}}
        </span>
    </template>
    </vue-good-table>
  </base-material-card>
  </v-container>
</template>

<script>
/* eslint-disable */
import axios from 'axios'
export default {
  name: 'CenterDashboard',
  data () {
    return {
      // searchTerm: '',
      newPostErrorMessage: null,
      wilayas: [],
      communues: [],
      selectedWilaya: {
        name:null,
        communues: []
      },
      statusList: ['En attente', 'Accepté', 'Réfusé'],
      selectedCommunue: null,
      showPackageModal: false,
      showHisotryModal: false,
      isLoading: false,
      totalRows: 0,
      tableFilters: {
        approved: ''
      },
      outgoingTableFilters: {
        id: null,
        name: null,
        status: null,
        from_wilaya: null,
        date: null
      },
      history: {
        wilaya: null,
        communue: null,
        tracking_id: null,
        show: false,
        message: null,
        creation_date: {
          date: null,
          time: null
        }
      },
      packageDetail: {
        home_fees:0,
      },
      columns:[
        {
          label: 'Nom',
          field: 'name',
          width: '200px',
          filterOptions: {
            enabled:true,
            placeholder: 'Entrer le nom',
            // trigger: 'enter',
          }
        },
        {
          label: 'Numéro de téléphone',
          field: 'phone',
          width: '200px',
          filterOptions: {
            enabled:true,
            placeholder: 'Enter le numéro',
            // trigger: 'enter',
          }
        },
        {
          label: 'Wilaya',
          field: 'wilaya',
          width: '100px',
          filterOptions: {
            enabled: true,
            placeholder: 'Choisir la wilaya',
            // trigger: 'enter',
          },
        },
        {
          label: 'Commune',
          field: 'communue',
          width: '100px',
          filterOptions: {
            enabled: false,
            placeholder: 'Choisir la commune',
            // trigger: 'enter',
          },
        },
        {
          label: 'Adresse',
          field: 'address',
          width: '110px',
          filterOptions: {
            enabled: true,
            placeholder: 'Enter le numéro',
            // trigger: 'enter',
          },
        },
      ],
      newPost: {
        email:null,
        password:null,
        wilaya:null,
        communue:null,
        phone:null,
        address:null,
        name: null,
        home_fees: null,
        code: null
      },
      rows: [],
      outgoingRows: [],
      showLoginModal: false,
      showPostModal: false,
      preparedRow: null,
      preparedAction: null,
      feesExceptions: [],
      popoverShow : false,
    }
  },
  mounted() {
    axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
    this.getWilayas()
    this.loadPostCenters()
    // this.columns[4].filterOptions.filterDropdownItems = this.statusList
    // this.$set(this.columns[4].filterOptions, 'filterDropdownItems', this.statusList);
  },
  methods: {
    onClose(){
      this.popoverShow = false
    },
    delete_post(){
      axios.post('delete-post',{auth_token: localStorage.getItem('auth_token'),
      code: this.packageDetail.code}).then(response =>{
        this.popoverShow = false
        this.showLoginModal = false
        this.loadPostCenters()
      })
    },
    getWilayas() {
      axios.post('get-wilayas').then(response => {
        if (response.data.status == 'success') {
          this.wilayas = response.data.data
          let wilayas = []
          this.wilayas.forEach(element => {
            wilayas.push(element.name)
          });
          this.$set(this.columns[2].filterOptions, 'filterDropdownItems', wilayas);
          // this.columns[2].filterOptions.filterDropdownItems = wilayas
        }
      })
    },
    prepareAction(row,action) {
      this.preparedRow = row
      this.preparedAction = action
      this.showLoginModal = true
    },
    confirmAction() {
      if (this.preparedAction == 'accept') this.acceptProvider(this.preparedRow)
      else if (this.preparedAction == 'refuse') this.refuseProvider(this.preparedRow)
      this.showLoginModal = false
      this.loadPostCenters()
    },
    handleModalHide(params) {
      this.showPackageModal = false
      this.showHisotryModal = false
      this.showPostModal = false
      this.history.show = false
      this.newPostErrorMessage = null
    },
    hideModal(params) {
      this.showPackageModal = false
      this.showHisotryModal = false
      this.showPostModal = false
      this.history.show = false
      this.newPostErrorMessage = null
    },
    loadPostCenters() {
      axios.post('get-post-centers').then(response => {
        if (response.data.status == 'success')
          this.rows = response.data.data
      })
    },
    changeStatus(row, tableType) {
      axios.post('center-change-status', {
        auth_token: localStorage.getItem('auth_token'),
        tracking_id: row.tracking_id,
        new_status: row.status
      }).then(response => {
        if (response.data.status == 'success') {
          if (tableType == 'incoming') this.loadIncomingItems()
          else if (tableType == 'outgoing') this.loadOutgoingItems()
        }
      })
    },
    onColumnFilter(params) {
      console.log(params)
      this.tableFilters = params.columnFilters
      if (params.columnFilters.approved_value == this.statusList[0]) this.tableFilters.approved = null
      else if (params.columnFilters.approved_value == this.statusList[1]) this.tableFilters.approved = true
      else if (params.columnFilters.approved_value == this.statusList[2]) this.tableFilters.approved = false
      else this.tableFilters.approved = ''
      this.loadPostCenters()
    },
    onSortChange(params) {
      let t = params[0].type
      let f = params[0].field
      this.rows.sort(function(a, b) {
        if (t == 'asc') {
          if ( a[f] < b[f] ){
            return -1;
          }
          if ( a[f] > b[f] ){
            return 1;
          }
        }
        else if (t == 'desc') {
          if ( a[f] < b[f] ){
            return 1;
          }
          if ( a[f] > b[f] ){
            return -1;
          }
        }
      });
    },
    onPageChange(params) {
      console.log(params)
    },
    onPerPageChange(params) {
      console.log(params)
    },
    // this.$set(this.columns[5].filterOptions, 'filterValue', '');
    refuseProvider(row) {
      axios.post('admin-refuse-provider', {
        auth_token: localStorage.getItem('auth_token'),
        email: row.email
      }).then(response => {
        if (response.data.status == 'success') {
          row.approved = false
        }
      })
    },
    acceptProvider(row) {
      axios.post('admin-accept-provider', {
        auth_token: localStorage.getItem('auth_token'),
        email: row.email
      }).then(response => {
        if (response.data.status == 'success') {
          row.approved = true
        }
      })
    },
    addFeesException() {
      this.feesExceptions.push({
        post_code: null,
        fees: null
      })
    },
    removeFeesExeception(index) {
      this.feesExceptions.splice(index,1)
    },
    changeFees() {
      axios.post('admin-change-post-fees', {
        auth_token: localStorage.getItem('auth_token'),
        code: this.packageDetail.code,
        home_fees: this.packageDetail.home_fees,
        fees_exceptions: this.feesExceptions,
        dlv_type: this.packageDetail.dlv_type,
      }).then(response => {
        if (response.data.status == 'success') {
          this.showLoginModal = false
          this.loadPostCenters()
        }
      })
    },
    addNewPost() {
      axios.post('admin-add-post', {
        auth_token: localStorage.getItem('auth_token'),
        post_info: this.newPost,
        fees_exceptions: this.feesExceptions
      }).then(response => {
        if (response.data.status == 'success') {
          this.loadPostCenters()
          this.showPostModal = false
          this.newPost= {
            email:null,
            password:null,
            wilaya:null,
            communue:null,
            phone:null,
            address:null,
            name: null,
            home_fees: null,
            code: null
          }
          this.feesExceptions = []
          this.newPostErrorMessage = null
        } else {
          this.newPostErrorMessage = response.data.error_message
        }
      })
    },
  },
}
</script>
<style>
.vgt-responsive {
  min-height: 450px;
}
</style>
